export const Title = () => (
  <h1
    className={[
      `font-black`,
      `text-3xl`,
      `text-[white]`,
      `text-center`,
      `md:text-left`,
    ].join(` `)}
  >
    CREATE YOUR OWN REALITY
  </h1>
);

export const Subtitle = () => (
  <p
    className={[
      `text-[white]`,
      `mt-6`,
      `mb-10`,
      `text-xl`,
      `text-center`,
      `md:text-left`,
    ].join(` `)}
  >
    Positive self-talk creates a powerful attitude. Convert your negative
    thoughts into positive ones.
  </p>
);
